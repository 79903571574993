export const CHAIN = 5;
export const PORTIS_ID = '85b7b96d-4306-4ed6-8625-b72faf57f363';

export const DEVELOPERS_HOME_URL =
  process.env.REACT_APP_DEVELOPERS_HOME_URL || 'https://developers.iex.ec';

export const TRUSTED_WORKERPOOL =
  process.env.REACT_APP_TRUSTED_WORKERPOOL ||
  '0x0000000000000000000000000000000000000000';
export const UNTRUSTED_APP = '0x4c99d2C28882e219683D785F6e3d9edaCe3b1A70';
export const TRUSTED_APP = '0x1E3878510933a592fc5BfD2d92fD791d5A4Ec007';
export const UNTRUSTED_ORACLE = '0x595977ed20f93a75e3bb4497b23833591a22897a';
export const TRUSTED_ORACLE = '0x06f0825cF7AF1DD0986eF40C273a51f1a032C8Dd';

export const BROKER_API_URL =
  process.env.REACT_APP_BROKER_API_URL ||
  'https://broker.dev-blockchain.developers.iex.ec';
export const POCO_GRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/iexecblockchaincomputing/iexec-poco-v5-goerli';
export const ORACLE_GRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/iexecblockchaincomputing/iexec-blockchain-dev0';
export const POCO_GRAPH_WS_URL =
  'wss://api.thegraph.com/subgraphs/name/iexecblockchaincomputing/iexec-poco-v5-goerli';
export const ORACLE_GRAPH_WS_URL =
  'wss://api.thegraph.com/subgraphs/name/iexecblockchaincomputing/iexec-blockchain-dev0';

export const ACADEMY_LINK_ORACLES =
  'https://academy.iex.ec/category/decentralized-oracle/?utm_source=BlockchainDev0&utm_medium=link&utm_campaign=backlink';
