import Debug from 'debug';
import { providers } from 'ethers';
import Portis from '@portis/web3';
import { PORTIS_ID, CHAIN } from '../config';
const debug = Debug('service:web3');
const sleep = ms => new Promise(res => setTimeout(res, ms));

const portisChains = {
  '1': 'mainnet',
  '5': 'goerli',
};

let availableProviders = [];

let initialized = false;

let portis;

let web3Provider = {};

let onProviderChangedCb,
  onEnabledCb,
  onUserDeniedCb,
  onAccountChangeCb,
  onChainChangeCb,
  onDisconnectCb,
  onAvailableProvidersCb,
  userActionRequiredCb;

let currAccount, currChainID;

const safeWrapSend = (provider, method, params) => {
  if (provider.request && typeof provider.request === 'function') {
    debug('safe wrap send into provider.request()');
    return provider.request({
      method,
      params,
    });
  }
  if (provider.send && typeof provider.send === 'function') {
    debug('safe wrap send into provider.send()');
    return provider.send(method, params);
  }
  throw Error('missing send method');
};

const checkInit = ({ strict = true } = {}) => {
  if (!initialized) {
    if (strict) throw Error('web3 is not initialized, call init() first');
    return false;
  }
  return true;
};

const checkProvider = ({ strict = true } = {}) => {
  checkInit({ strict });
  if (!web3Provider.web3) {
    if (strict) throw Error('Missing web3Provider, use chooseProvider() first');
    return false;
  }
  return true;
};

const checkProviderEnabled = ({ strict = true } = {}) => {
  checkProvider({ strict });
  if (!web3Provider.isEnabled) {
    if (strict)
      throw Error('web3Provider is not enabled, use enableProvider() first');
    return false;
  }
  return true;
};

const getUserAccount = async ({ strict = false, noCheck = false } = {}) => {
  if (!noCheck) checkProviderEnabled();
  try {
    const address = await new providers.Web3Provider(web3Provider.web3)
      .getSigner()
      .getAddress();
    return address;
  } catch (error) {
    debug('getUserAccount()', error);
    if (strict) throw error;
  }
};

const getNetworkVersion = async ({ strict = false, noCheck = false } = {}) => {
  if (!noCheck) checkProviderEnabled();
  try {
    const { chainId } = await new providers.Web3Provider(
      web3Provider.web3,
    ).getNetwork();
    return chainId;
  } catch (error) {
    debug('getNetworkVersion()', error);
    if (strict) throw error;
    return '';
  }
};

export const chooseProvider = async (
  providerName,
  { chainId = CHAIN } = {},
) => {
  debug('chooseProvider()', providerName, chainId);
  checkInit();
  if (!availableProviders.includes(providerName)) {
    const msg = `Unknown provider "${providerName}"`;
    debug('chooseProvider()', msg);
    if (userActionRequiredCb)
      userActionRequiredCb({
        level: 'error',
        message: msg,
      });
    if (onUserDeniedCb) onUserDeniedCb();
    return;
  }
  web3Provider = {
    web3: null,
    name: undefined,
    isEnabled: false,
    providerApi: {},
  };
  switch (providerName) {
    case 'metamask':
      if (window.ethereum && window.ethereum.isMetaMask) {
        web3Provider.web3 = window.ethereum;
        web3Provider.name = 'metamask';
      } else if (window.web3) {
        web3Provider.web3 = window.web3;
        web3Provider.name = 'metamask';
      } else {
        const msg = `${providerName} is no longer available, connection aborted`;
        debug('chooseProvider()', msg);
        if (userActionRequiredCb)
          userActionRequiredCb({
            level: 'error',
            message: msg,
          });
        if (onUserDeniedCb) onUserDeniedCb();
        return;
      }
      break;
    case 'portis':
      let portisChain = portisChains[chainId];
      if (!portisChain) {
        const msg = `Chain ${chainId} is not available, connection aborted`;
        debug('chooseProvider()', msg);
        if (userActionRequiredCb)
          userActionRequiredCb({
            level: 'error',
            message: msg,
          });
        if (onUserDeniedCb) onUserDeniedCb();
        return;
      }
      if (portis) {
        debug('portis.changeNetwork()');
        portis.changeNetwork(portisChain);
      } else {
        debug('new Portis()', portisChain);
        portis = new Portis(PORTIS_ID, portisChain);
        portis.changeNetwork(portisChain); // workaround
      }
      web3Provider.web3 = portis.provider;
      web3Provider.providerApi = portis;
      web3Provider.name = 'portis';
      break;
    default:
      const msg = `${providerName} is not supported, connection aborted`;
      debug('chooseProvider()', msg);
      if (userActionRequiredCb)
        userActionRequiredCb({
          level: 'error',
          message: msg,
        });
      if (onUserDeniedCb) onUserDeniedCb();
      return;
  }
  debug(`web3 provider selected ${web3Provider.name}`);
  if (onProviderChangedCb) onProviderChangedCb(web3Provider.name);
  await enableProvider();
};

export const enableProvider = async () => {
  checkProvider();
  try {
    debug("waiting for web3.send('eth_requestAccounts')");
    const errorPromise = () =>
      new Promise((res, reject) => {
        if (web3Provider.name === 'portis') {
          web3Provider.providerApi.onError(e => {
            availableProviders = availableProviders.filter(e => e !== 'portis');
            if (onAvailableProvidersCb)
              onAvailableProvidersCb(availableProviders);
            reject(e);
          });
        }
      });
    const enablePromise = async () => {
      try {
        const res = await safeWrapSend(
          web3Provider.web3,
          'eth_requestAccounts',
        );
        if (!res)
          throw Error(`web3.send('eth_requestAccounts') returned undefined`);
      } catch (error) {
        if (error.code && error.code === 4001) throw error;
        debug(
          "web3.send('eth_requestAccounts') failled, fallback to web3.enable()",
          error,
        );
        await web3Provider.web3.enable();
      }
    };
    await Promise.race([enablePromise(), errorPromise()]);
    [currAccount, currChainID] = await Promise.all([
      getUserAccount({ strict: true, noCheck: true }),
      getNetworkVersion({ strict: true, noCheck: true }),
    ]);
    web3Provider.isEnabled = true;
    if (onEnabledCb) onEnabledCb(currChainID, currAccount);
    debug('web3 provider enabled');
  } catch (error) {
    if (onUserDeniedCb) onUserDeniedCb();
    debug('enableProvider()', error);
  }
};

export const disconnectProvider = () => {
  checkProvider();
  try {
    switch (web3Provider.name) {
      case 'metamask':
        break;
      case 'portis':
        web3Provider.providerApi.logout();
        break;
      default:
        break;
    }
  } catch (error) {
    debug('disconnectProvider()', error);
  }
  web3Provider = {
    web3: null,
    name: undefined,
    isEnabled: false,
    providerApi: {},
  };
  debug(`web3 provider disconnected`);
};

export const init = async ({
  onProviderChanged,
  onEnabled,
  onUserDenied,
  onAccountChange,
  onChainChange,
  onDisconnect,
  onAvailableProviders,
  userActionRequired,
  autoLogin = false,
  defaultProvider,
  defaultChainId,
}) => {
  if (initialized) {
    debug('Already initialized');
    return;
  }
  onProviderChangedCb = onProviderChanged;
  onEnabledCb = onEnabled;
  onUserDeniedCb = onUserDenied;
  onAccountChangeCb = onAccountChange;
  onChainChangeCb = onChainChange;
  onDisconnectCb = onDisconnect;
  onAvailableProvidersCb = onAvailableProviders;
  userActionRequiredCb = userActionRequired;

  const checkChainID = async () => {
    if (checkProviderEnabled({ strict: false })) {
      const newChainID = parseInt(await getNetworkVersion(), 10);
      if (newChainID && newChainID !== currChainID) {
        debug(`chain changed ${currChainID} -> ${newChainID}`);
        currChainID = newChainID;
        if (onChainChangeCb) onChainChangeCb(currChainID);
      }
    }
    await sleep(500);
    checkChainID();
  };

  const checkAccount = async () => {
    let newAccount;
    if (checkProviderEnabled({ strict: false })) {
      const accountAddress = await getUserAccount();
      newAccount = accountAddress ? accountAddress : undefined;
      if (!currAccount && newAccount) {
        debug(`web3 provider connected ${currAccount} -> ${newAccount}`);
        if (onAccountChangeCb) onAccountChangeCb(newAccount);
      } else if (currAccount && !newAccount) {
        debug(`web3 provider disconnected ${currAccount} -> ${newAccount}`);
        if (onDisconnectCb) onDisconnectCb();
        web3Provider.isEnabled = false;
      } else if (newAccount !== currAccount) {
        debug(`account changed ${currAccount} -> ${newAccount}`);
        if (onAccountChangeCb) onAccountChangeCb(newAccount);
      }
      currAccount = newAccount;
    } else {
      if (currAccount) {
        debug(`web3 provider disconnected ${currAccount} -> ${newAccount}`);
        if (onDisconnectCb) onDisconnectCb();
        web3Provider.isEnabled = false;
        currAccount = undefined;
      }
    }
    await sleep(500);
    checkAccount();
  };

  //add portis
  availableProviders.push('portis');
  //try metamask
  if (window.ethereum && window.ethereum.isMetaMask) {
    availableProviders.push('metamask');
  }

  checkChainID();
  checkAccount();

  initialized = true;

  if (onAvailableProvidersCb) onAvailableProvidersCb(availableProviders);

  if (autoLogin && defaultProvider) {
    chooseProvider(defaultProvider, { chainId: defaultChainId });
  }
};

export const getWeb3 = () => {
  checkProviderEnabled();
  return web3Provider.web3;
};
