import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';
import StepConnector from '@material-ui/core/StepConnector';

const StyledStepIcon = withStyles(theme => ({
  root: {
    height: '1.2em',
    width: '1.2em',
    color: theme.palette.secondary.light,
    '&$completed': {
      color: theme.palette.text.primary,
    },
    '&$active': {
      color: theme.palette.text.primary,
    },
  },
  text: {
    fill: theme.palette.text.disabled,
    fontSize: '0.85rem',
    fontWeight: 'bolder',
  },
  active: {},
  completed: {},
  error: {},
}))(StepIcon);

const useConnectorStyles = makeStyles(theme => ({
  alternativeLabel: {
    fontWeight: 'bold',
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  line: {
    borderColor: 'transparent',
    backgroundColor: theme.palette.secondary.light,
    height: '0.3rem',
    borderRadius: '0.15rem',
  },
}));

const useStepperStyles = makeStyles(theme => ({
  root: {
    minHeight: '8.2rem',
    backgroundColor: 'transparent',
  },
}));

const useLabelStyle = makeStyles(theme => ({
  label: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
    '&$active': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    '&$completed': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    '& a': {
      fontSize: '0.7rem',
      color: theme.palette.text.link,
      textDecoration: 'none',
    },
  },
  active: {},
  completed: {},
}));

const Stepper = ({ steps, activeStep, classes }) => {
  const labelClasses = useLabelStyle();
  const stepperStyles = useStepperStyles();
  const connectorClasses = useConnectorStyles();
  return (
    <MuiStepper
      className={'stepper-guide'}
      activeStep={activeStep}
      alternativeLabel
      connector={<StepConnector classes={connectorClasses} />}
      classes={stepperStyles}
    >
      {steps &&
        steps.map(({ label, link }) => (
          <Step key={label}>
            <StepLabel
              classes={labelClasses}
              StepIconComponent={StyledStepIcon}
            >
              <div>{label}</div>
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  Check on Etherscan
                </a>
              )}
            </StepLabel>
          </Step>
        ))}
    </MuiStepper>
  );
};

export default Stepper;
