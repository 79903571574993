import { gql } from '@apollo/client';
import bagdeIcon from '../../../assets/oracles/icon-badge.svg';
import {
  loadState,
  saveState,
  loadSessionState,
  saveSessionState,
  BLOCKCHAIN_DEV_0_STATE,
} from '../../../utils/localStorage';
import { ORACLE_STATUS } from '../../../utils/iexec-utils';
import { ACADEMY_LINK_ORACLES } from '../../../config';

const OLD_TASK_DISPLAY_TIMEOUT = 120;

export const getStateLoader = oracleName => address => {
  const state = loadState(BLOCKCHAIN_DEV_0_STATE);
  return (state && state[address] && state[address][oracleName]) || {};
};

export const getStateUpdater = oracleName => (address, newState) => {
  const state = loadState(BLOCKCHAIN_DEV_0_STATE) || {};
  const addressState = state[address] || {};
  const oracleState = addressState[oracleName] || {};
  const updatedState = {
    ...state,
    [address]: {
      ...addressState,
      [oracleName]: { ...oracleState, ...newState },
    },
  };
  saveState(BLOCKCHAIN_DEV_0_STATE, updatedState);
};

export const getSessionStateLoader = oracleName => address => {
  const state = loadSessionState(BLOCKCHAIN_DEV_0_STATE);
  return (state && state[address] && state[address][oracleName]) || {};
};

export const getSessionStateUpdater = oracleName => (address, newState) => {
  const state = loadSessionState(BLOCKCHAIN_DEV_0_STATE) || {};
  const addressState = state[address] || {};
  const oracleState = addressState[oracleName] || {};
  const updatedState = {
    ...state,
    [address]: {
      ...addressState,
      [oracleName]: { ...oracleState, ...newState },
    },
  };
  saveSessionState(BLOCKCHAIN_DEV_0_STATE, updatedState);
};

export const TASK_QUERY = gql`
  query task($dealid: ID!) {
    deal(id: $dealid) {
      startTime
      category {
        workClockTimeRef
      }
      tasks(first: 1) {
        taskid: id
        status
        events {
          type: __typename
          transaction {
            txHash: id
            timestamp
          }
        }
      }
    }
  }
`;

export const TASK_SUBSCRIPTION = gql`
  subscription task($dealid: ID!) {
    deal(id: $dealid) {
      startTime
      category {
        workClockTimeRef
      }
      tasks(first: 1) {
        taskid: id
        status
        events {
          type: __typename
          transaction {
            txHash: id
            timestamp
          }
        }
      }
    }
  }
`;

export const formatTaskQueryResult = (taskQuery, now) => {
  let task;
  if (taskQuery.data && taskQuery.data.deal) {
    const deal = taskQuery.data.deal;
    const task0 = deal.tasks[0];
    task = {};
    task.finalDeadline =
      parseInt(deal.startTime) + parseInt(deal.category.workClockTimeRef) * 10;
    task.finalDeadlineReached = task.finalDeadline * 1000 < now;
    if (task0) {
      task.taskid = task0.taskid;
      task.status = task0.status;
      task.events = task0.events.reduce((acc, curr) => {
        const { type } = curr;
        const { txHash } = curr.transaction;
        const timestamp = parseInt(curr.transaction.timestamp);
        return { ...acc, [type]: { txHash, timestamp } };
      }, {});
    }
    task.isTooOld =
      (task.finalDeadline + OLD_TASK_DISPLAY_TIMEOUT) * 1000 < now ||
      (task.events &&
        task.events.TaskFinalize &&
        (task.events.TaskFinalize.timestamp + OLD_TASK_DISPLAY_TIMEOUT) * 1000 <
          now) ||
      false;
    task.isRunning =
      (!task.finalDeadlineReached &&
        task.status !== 'COMPLETED' &&
        task.status !== 'FAILLED') ||
      false;
  }
  return task;
};

export const VALID_UPDATE_QUERY = gql`
  query isValidUpdate($taskid: Bytes!) {
    valueReceiveds(where: { oracleCallID: $taskid }) {
      oracleCallID
      isValid
    }
  }
`;

export const VALID_UPDATE_SUBSCRIPTION = gql`
  subscription isValidUpdate($taskid: Bytes!) {
    valueReceiveds(where: { oracleCallID: $taskid }) {
      oracleCallID
      isValid
    }
  }
`;

export const formatIsValidUpdateQueryResult = isValidUpdateQuery => {
  if (
    isValidUpdateQuery.data &&
    isValidUpdateQuery.data.valueReceiveds &&
    isValidUpdateQuery.data.valueReceiveds[0]
  ) {
    return isValidUpdateQuery.data.valueReceiveds[0].isValid;
  }
  return undefined;
};

export const ORACLE_QUERY = gql`
  query oracle($address: ID!) {
    oracle(id: $address) {
      id
      values(
        where: { isValid: true }
        first: 10
        orderBy: date
        orderDirection: desc
      ) {
        value
        date
        requester {
          id
        }
        taskid: oracleCallID
        isValid
      }
    }
  }
`;

export const ORACLE_SUBSCRIPTION = gql`
  subscription oracle($address: ID!) {
    oracle(id: $address) {
      id
      values(
        where: { isValid: true }
        first: 10
        orderBy: date
        orderDirection: desc
      ) {
        value
        date
        requester {
          id
        }
        taskid: oracleCallID
        isValid
      }
    }
  }
`;

export const formatOracleQueryResult = oracleQuery => {
  if (
    oracleQuery.data &&
    oracleQuery.data.oracle &&
    oracleQuery.data.oracle.values
  ) {
    return oracleQuery.data.oracle.values.map(val => ({
      value: val.value,
      requester: val.requester.id,
    }));
  }
  return [];
};

export const getStepper = ({ dealid, txHash, task, isMatching }) => {
  let activeStep = -1;
  if (isMatching) {
    activeStep = 0;
  } else if (dealid && task && !task.isTooOld) {
    activeStep = 1;
    if (task && task.events && task.events.TaskInitialize) {
      activeStep++;
      if (task && task.events && task.events.TaskContribute) {
        activeStep++;
        if (task && task.events && task.events.TaskFinalize) {
          activeStep++;
        }
      }
    }
  }
  const stepper = {
    activeStep,
    steps: [
      {
        label: 'Matching orders',
      },
      {
        label: 'Deal concluded',
        link: activeStep >= 1 && txHash && getEtherscanLink(txHash),
      },
      {
        label: 'Task Initialized',
        link:
          activeStep >= 2 &&
          task &&
          task.events.TaskInitialize &&
          getEtherscanLink(task.events.TaskInitialize.txHash),
      },
      {
        label: 'Task Contributed',
        link:
          activeStep >= 3 &&
          task &&
          task.events.TaskContribute &&
          getEtherscanLink(task.events.TaskContribute.txHash),
      },
      {
        label: 'Task Finalized',
        link:
          activeStep >= 4 &&
          task &&
          task.events.TaskFinalize &&
          getEtherscanLink(task.events.TaskFinalize.txHash),
      },
    ],
  };
  return stepper;
};

const getEtherscanLink = txHash =>
  txHash ? `https://goerli.etherscan.io/tx/${txHash}` : undefined;

export const getHeadquarter = ({
  dealid,
  task,
  isSending,
  isValidUpdate,
  address,
  values,
  oracleAddress,
}) => {
  let oracleStatus = ORACLE_STATUS.NONE;
  if (isSending || (dealid && !task) || (task && task.isRunning)) {
    oracleStatus = ORACLE_STATUS.WAIT;
  } else if (task && !task.isTooOld) {
    if (isValidUpdate === true) {
      oracleStatus = ORACLE_STATUS.SUCCESS;
    }
    if (isValidUpdate === false) {
      oracleStatus = ORACLE_STATUS.FAIL;
    }
  }
  const headquarter = {
    status: oracleStatus,
    address,
    values,
    link:
      oracleAddress &&
      `https://goerli.etherscan.io/address/${oracleAddress}#code`,
  };
  return headquarter;
};

export const getNavigationSteps = activeStep => {
  const steps = [
    {
      stepNum: 1,
      stepText: 'Decentralized Oracle without TEE',
      active: false,
      image: undefined,
      link: { to: '/oracles/' },
      guideClassName: 'navigate-untrusted-guide',
    },
    {
      stepNum: 2,
      stepText: 'iExec Decentralized Oracles with TEE',
      active: false,
      image: undefined,
      link: { to: '/oracles/trusted' },
      guideClassName: 'navigate-trusted-guide',
    },
    {
      stepNum: 3,
      stepText: 'Learn more on iExec Academy',
      active: false,
      image: bagdeIcon,
      link: {
        href: ACADEMY_LINK_ORACLES,
      },
      guideClassName: 'navigate-academy-guide',
    },
  ];
  if (steps[activeStep]) {
    steps[activeStep].active = true;
  }
  return steps;
};
