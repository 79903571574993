import React, { useState, useEffect } from 'react';

export const ClockContext = React.createContext(null);

const ClockProvider = props => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(Date.now());
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <ClockContext.Provider
      value={{
        now,
      }}
    >
      {props.children}
    </ClockContext.Provider>
  );
};

export default ClockProvider;
