import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { WebSocketLink } from 'apollo-link-ws'; // (require subscriptions-transport-ws)
import {
  POCO_GRAPH_URL,
  POCO_GRAPH_WS_URL,
  ORACLE_GRAPH_URL,
  ORACLE_GRAPH_WS_URL,
} from '../config';

export const pocoClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: POCO_GRAPH_URL,
  }),
  queryDeduplication: true,
});

export const pocoWsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new WebSocketLink({
    uri: POCO_GRAPH_WS_URL,
    options: {
      reconnect: true,
    },
  }),
  queryDeduplication: true,
});

export const oracleClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: ORACLE_GRAPH_URL,
  }),
  queryDeduplication: true,
});

export const oracleWsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new WebSocketLink({
    uri: ORACLE_GRAPH_WS_URL,
    options: {
      reconnect: true,
    },
  }),
  queryDeduplication: true,
});
