import React, { useContext, useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import { useSubscription } from '@apollo/client';
import { WalletContext } from '../../provider/WalletProvider';
import { ClockContext } from '../../provider/ClockProvider';
import { pocoWsClient, oracleWsClient } from '../../../services/graphql';
import {
  createAndSignRequest,
  sendOrderAndGetDeal,
  matchOrderAndGetDeal,
} from '../../../utils/iexec-utils';

import {
  UNTRUSTED_APP,
  TRUSTED_APP,
  UNTRUSTED_ORACLE,
  TRUSTED_WORKERPOOL,
  BROKER_API_URL,
} from '../../../config';

import {
  getStateLoader,
  getStateUpdater,
  getSessionStateLoader,
  getSessionStateUpdater,
  getStepper,
  getNavigationSteps,
  TASK_SUBSCRIPTION,
  formatTaskQueryResult,
  ORACLE_SUBSCRIPTION,
  formatOracleQueryResult,
  VALID_UPDATE_SUBSCRIPTION,
  formatIsValidUpdateQueryResult,
  getHeadquarter,
} from './common';

import comics1 from '../../../assets/oracles/bd-1.svg';
import comics2 from '../../../assets/oracles/bd-2.svg';
import comics3 from '../../../assets/oracles/bd-3.svg';
import comics4 from '../../../assets/oracles/bd-4.svg';

const OARCLE_NAME = 'untrusted';
const loadExistingState = getStateLoader(OARCLE_NAME);
const updateState = getStateUpdater(OARCLE_NAME);
const loadExistingSessionState = getSessionStateLoader(OARCLE_NAME);
const updateSessionState = getSessionStateUpdater(OARCLE_NAME);

const title = 'Decentralized Oracle (without TEE)';
const isTrusted = false;
const subtitle = 'TEE not activated';

const comicsImages = [comics1, comics2, comics3, comics4];

const comics = {
  images: comicsImages,
};

const navigation = {
  steps: getNavigationSteps(0),
};

const requestSign = web3Provider => async ([app, oracle, isTee, args]) => {
  try {
    const tag = isTee ? ['tee'] : [];
    const signedOrder = await createAndSignRequest(web3Provider, {
      app,
      category: 0,
      workerpool: TRUSTED_WORKERPOOL,
      callback: oracle,
      params: {
        iexec_args: args,
      },
      tag,
    });
    return signedOrder;
  } catch (e) {
    return null;
  }
};

const requestMatch = web3Provider => async ([signedOrder]) => {
  try {
    const { dealid, txHash } = BROKER_API_URL
      ? await sendOrderAndGetDeal(signedOrder)
      : await matchOrderAndGetDeal(web3Provider, signedOrder);
    return { dealid, txHash };
  } catch (e) {
    return null;
  }
};

const UntrustedOracleContainer = props => {
  const { getWeb3Provider, address } = useContext(WalletContext);
  const { now } = useContext(ClockContext); // use ticker to update timeout

  // load previous session
  const [dealid, setDealid] = useState(loadExistingState(address).dealid);
  const [txHash, setTxHash] = useState(loadExistingState(address).txHash);
  useEffect(() => {
    setDealid(loadExistingState(address).dealid);
    setTxHash(loadExistingState(address).txHash);
  }, [address]);

  // watch last task
  const taskQuery = useSubscription(TASK_SUBSCRIPTION, {
    variables: {
      dealid: dealid,
    },
    client: pocoWsClient,
  });
  const task = formatTaskQueryResult(taskQuery, now);
  // console.log('task', task);

  // inputs
  const web3Provider = getWeb3Provider();
  const matchRequest = useAsync({
    deferFn: requestMatch(web3Provider),
    onResolve: res => {
      if (res) {
        const { dealid, txHash } = res;
        updateState(address, { dealid, txHash });
        setDealid(dealid);
        setTxHash(txHash);
      }
    },
  });
  const signRequest = useAsync({
    deferFn: requestSign(web3Provider),
    onResolve: order => {
      if (order) {
        matchRequest.run(order);
      }
    },
  });
  const isSigning = signRequest.isPending || false;
  const isSending = matchRequest.isPending || false;
  const isSigningOrSending = isSigning || isSending || false;

  const isReadyToSend =
    !isSigningOrSending && (!dealid || (task && !task.isRunning));

  const inputs = {
    isReadyToSend: isReadyToSend,
    handleAllySend: () => {
      signRequest.run(TRUSTED_APP, UNTRUSTED_ORACLE, false);
    },
    handleEnemySend: value => {
      signRequest.run(UNTRUSTED_APP, UNTRUSTED_ORACLE, false, value);
    },
  };

  // pigeon spinner
  const pigeon = {
    isInFlight: task && task.isRunning,
  };

  // stepper
  const stepper = getStepper({
    dealid,
    txHash,
    task,
    isMatching: isSigningOrSending,
  });

  // headquarter
  const oracleQuery = useSubscription(ORACLE_SUBSCRIPTION, {
    variables: {
      address: UNTRUSTED_ORACLE.toLowerCase(),
    },
    client: oracleWsClient,
  });
  const values = formatOracleQueryResult(oracleQuery);
  const validUpdateQuery = useSubscription(VALID_UPDATE_SUBSCRIPTION, {
    variables: {
      taskid: task && task.taskid,
    },
    client: oracleWsClient,
  });
  const isValidUpdate = formatIsValidUpdateQueryResult(validUpdateQuery);
  const headquarter = getHeadquarter({
    dealid,
    task,
    isSending,
    isValidUpdate,
    address,
    values,
    oracleAddress: UNTRUSTED_ORACLE,
  });

  const sandbox = {
    subtitle,
    isTrusted,
    headquarter,
    inputs,
    pigeon,
    stepper,
  };

  // guide
  const [showGlobalGuide, setShowGlobalGuide] = useState(
    !loadExistingSessionState(address).globalGuideEnd,
  );
  const [showNavigationGuide, setShowNavigationGuide] = useState(false);
  const onGlobalTourEnd = () => {
    updateSessionState(address, { globalGuideEnd: true });
    setShowGlobalGuide(false);
  };
  const onNavigationTourEnd = () => {
    updateSessionState(address, { navigationGuideEnd: true });
    setShowNavigationGuide(false);
  };
  const guide = {
    showGlobalGuide,
    onGlobalTourEnd,
    showNavigationGuide,
    onNavigationTourEnd,
  };
  useEffect(() => {
    if (
      task &&
      task.events &&
      task.events.TaskFinalize &&
      !loadExistingSessionState(address).navigationGuideEnd
    ) {
      setShowNavigationGuide(true);
    }
  }, [task, address]);

  return (
    <>
      {React.Children.map(props.children, (child, i) => {
        return React.cloneElement(child, {
          title,
          comics,
          sandbox,
          navigation,
          guide,
        });
      })}
    </>
  );
};

export default UntrustedOracleContainer;
