import { providers } from 'ethers';
import { IExec, utils } from 'iexec';
import fetch from 'cross-fetch';
import { BROKER_API_URL } from '../config';

export const createAndSignRequest = async (web3Provider, order) => {
  try {
    const { chainId } = await new providers.Web3Provider(
      web3Provider,
    ).getNetwork();
    const iexec = new IExec({
      ethProvider: web3Provider,
      chainId,
    });
    const orderToSign = await iexec.order.createRequestorder(order);
    const signedOrder = await iexec.order.signRequestorder(orderToSign, {
      checkRequest: false,
    });
    return signedOrder;
  } catch (e) {
    console.error('Failed to sign order', e);
    throw new Error('Failed to sign order');
  }
};

// dev
export const matchOrderAndGetDeal = async (web3Provider, order) => {
  try {
    const { chainId } = await new providers.Web3Provider(
      web3Provider,
    ).getNetwork();
    const iexec = new IExec({
      ethProvider: web3Provider,
      chainId,
    });
    const appOrderbook = (await iexec.orderbook.fetchAppOrderbook(order.app))
      .orders;
    const workerpoolOrderbook = (await iexec.orderbook.fetchWorkerpoolOrderbook(
      {
        workerpoolAddress:
          order.workerpool !== utils.NULL_ADDRESS
            ? order.workerpool
            : undefined,
        category: order.category,
      },
    )).orders;
    const { txHash, dealid } = await iexec.order.matchOrders(
      {
        apporder: appOrderbook[0].order,
        workerpoolorder: workerpoolOrderbook[0].order,
        requestorder: order,
      },
      { checkRequest: false },
    );
    return { txHash, dealid };
  } catch (e) {
    console.error('Failed to match order', e);
    throw new Error('Failed to match order');
  }
};

export const sendOrderAndGetDeal = async order => {
  try {
    const res = await fetch(`${BROKER_API_URL}/submit`, {
      method: 'POST',
      body: JSON.stringify(order),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    if (!res.ok) {
      throw new Error(`Bad response: ${res.status}`);
    }
    const contentType = res.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') === -1) {
      throw new Error('The http response is not of JSON type');
    }
    const { matchs } = await res.json();
    if (!matchs || !Array.isArray(matchs) || !matchs[0]) {
      throw new Error('Missing matchs in response');
    }
    const {
      dealid,
      txHash,
      // volume,
    } = matchs[0];
    if (!dealid) {
      throw new Error('Missing dealid in response.matchs');
    }
    if (!txHash) {
      throw new Error('Missing txHash in response.matchs');
    }
    return {
      dealid,
      txHash,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to send order');
  }
};

export const ORACLE_STATUS = {
  NONE: 'NONE',
  WAIT: 'WAIT',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};
