import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { BOOTSTRAP_STYLES_MAP } from '../../utils/css-utils';

import logo from '../../assets/logo.svg';

const stylesMap = {
  ...BOOTSTRAP_STYLES_MAP,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 2px 4px 0 ${theme.palette.background.shadow}`,
    position: '-webkit-sticky, sticky',
    top: '0px',
    width: '100%',
    padding: '0.75rem 3.125rem',
    zIndex: '99',
    minWidth: '720px',
    ...stylesMap.dFlex,
    ...stylesMap.alignItemsCenter,
    ...stylesMap.justifyContentBetween,
  },
  container: {
    ...stylesMap.dFlex,
    ...stylesMap.alignItemsCenter,
    ...stylesMap.justifyContentStart,
  },
  logo: {
    height: '50px',
    verticalAlign: 'middle',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginLeft: '1rem',
    marginBottom: 0,
    display: 'inline-block',
  },
  userProfile: {
    ...stylesMap.dFlex,
    ...stylesMap.alignItemsCenter,
    ...stylesMap.justifyContentEnd,
  },
  userImage: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    marginLeft: '3rem',
  },
  popoverRoot: {
    top: '1.5rem !important',
    left: '-3.5rem !important',
  },
});

const Header = props => {
  const { title, link, userImage, connected, disconnect, classes } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleAccountClick = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDisconnect = () => {
    handleAccountMenuClose();
    disconnect();
  };

  return (
    <header className={classes.root}>
      <div className={classes.container}>
        <a href={link}>
          <img
            src={logo}
            className={classes.logo}
            alt="iExec"
            referrerPolicy="no-referrer"
          />
          <p className={classes.title}>{title}</p>
        </a>
      </div>
      {connected && (
        <div className={classes.userProfile}>
          <img
            src={userImage}
            className={classes.userImage}
            alt="iExec"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleAccountClick}
          />
          {/* known issue https://github.com/mui-org/material-ui/issues/22453 must be solved in material-ui@5 */}
          <Menu
            id="account-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={!!menuAnchorEl}
            onClose={handleAccountMenuClose}
            PopoverClasses={{
              root: classes.popoverRoot,
            }}
          >
            <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
          </Menu>
        </div>
      )}
    </header>
  );
};

export default withStyles(styles)(Header);
