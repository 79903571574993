import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import metamaskIcon from '../../assets/metamask_icon.svg';
import portisIcon from '../../assets/portis_icon.svg';
import chooseNetworkImg from '../../assets/choose-network.png';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
  },
  title: {},
  providerContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  provider: {
    width: '260px',
    minHeight: '400px',
    margin: '0 2rem',
    textAlign: 'center',
  },
  providerDesc: {
    minHeight: '3rem',
  },
  providerCard: {
    cursor: 'pointer',
    margin: '20px auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0 2px 4px 0 ${theme.palette.background.shadow}`,
    width: '200px',
    height: '200px',
    padding: '10px',
    '& img': {
      maxWidth: '180px',
      maxHeight: '180px',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    '&:hover': {
      '& img': {
        opacity: '1',
      },
    },
  },
  inactive: {
    '& img': {
      opacity: '0.5',
    },
    color: theme.palette.text.disabled,
    '& div': {
      boxShadow: 'none !important',
    },
  },
  button: {
    margin: '0 auto',
    marginTop: '2em',
  },
  spacer: {
    flex: 1,
  },
  hint: {
    fontSize: '1.2rem',
  },
  switchNetwork: {
    margin: 'auto',
    boxShadow: `0 2px 4px 0 ${theme.palette.background.shadow}`,
  },
});

const ChooseWallet = props => {
  const { availableProviders, connect, wrongChain, classes } = props;

  const [active, setActive] = useState();

  const hasError = !!wrongChain;
  return (
    <>
      {hasError && wrongChain && (
        <div className={props.classes.root}>
          <h1 className={classes.title}>Oops, you’re on the wrong network</h1>
          <div className={classes.hint}>
            <p>Simply open MetaMask and switch over to the</p>
            <strong>Goerli Test Network</strong>
          </div>
          <div className={classes.spacer} />

          <img
            className={classes.switchNetwork}
            src={chooseNetworkImg}
            alt="choose network"
          />
          <div className={classes.spacer} />
        </div>
      )}
      {!hasError && (
        <div className={props.classes.root}>
          <h1 className={classes.title}>Login with your Ethereum wallet</h1>
          <div className={classes.spacer} />
          <div className={classes.providerContainer}>
            <div
              className={classNames(
                classes.provider,
                active !== 'metamask' && classes.inactive,
              )}
            >
              {' '}
              <div className={classes.providerDesc}>
                Already familiar with blockchain, tokens, and wallets?
              </div>
              <div
                onClick={() => setActive('metamask')}
                className={classes.providerCard}
              >
                <img src={metamaskIcon} alt="MetaMask"></img>
              </div>
              <h2>MetaMask</h2>
              {active === 'metamask' && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => connect('metamask')}
                  disabled={!availableProviders.includes('metamask')}
                >
                  Login with MetaMask
                </Button>
              )}
            </div>
            <div
              className={classNames(
                classes.provider,
                active !== 'portis' && classes.inactive,
              )}
            >
              <div className={classes.providerDesc}>
                Never used a blockchain wallet?
              </div>
              <div
                onClick={() => setActive('portis')}
                className={classes.providerCard}
              >
                <img src={portisIcon} alt="Portis"></img>
              </div>
              <h2>Portis</h2>
              {active === 'portis' && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => connect('portis')}
                  disabled={!availableProviders.includes('portis')}
                >
                  Login with Portis
                </Button>
              )}
            </div>
          </div>
          <div className={classes.spacer} />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(ChooseWallet);
