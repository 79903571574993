import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

// import ChooseLevel from './ChooseLevel';
import LoginGuard from '../container/LoginGuard';
import UntrustedOracleContainer from '../container/oracles/UntrustedOracleContainer';
import TrustedOracleContainer from '../container/oracles/TrustedOracleContainer';
import Oracle from './oracles/Oracle';

const styles = theme => ({
  root: {
    flex: 1,
    width: '100%',
    minWidth: '720px',
    marginTop: '2rem',
    padding: '0 3rem',
  },
});

const Content = props => (
  <div className={props.classes.root}>
    <Switch>
      {/** skip choose level
      <Route path="/" exact>
        <ChooseLevel />
      </Route>
       */}
      <Route path="/oracles">
        <LoginGuard>
          <Switch>
            <Route path="/oracles/untrusted" exact>
              <UntrustedOracleContainer>
                <Oracle />
              </UntrustedOracleContainer>
            </Route>
            <Route path="/oracles/trusted" exact>
              <TrustedOracleContainer>
                <Oracle />
              </TrustedOracleContainer>
            </Route>
            <Redirect push={false} to="/oracles/untrusted" />
          </Switch>
        </LoginGuard>
      </Route>
      <Redirect push={false} to="/oracles" />
    </Switch>
  </div>
);

export default withStyles(styles)(Content);
