import React from 'react';
import Joyride, { EVENTS as joyrideEvents } from 'react-joyride';
import { withTheme } from '@material-ui/core/styles';

const globalGuide = {
  steps: [
    {
      target: '.comics-guide',
      content:
        'This comic strip provides an analogy to show the importance of having a decentralized oracle with TEE, that uses reliable and verified data.',
      disableBeacon: true, // auto start
    },
    {
      target: '.sandbox-guide',
      content:
        'Get hands-on! Try changing some parameters and sending some information to the smart contract.',
    },
    {
      target: '.ally-input-guide',
      content:
        'You can send information back to your allies at the headquarters, tell them how many soldiers are remaining.',
    },
    {
      target: '.enemy-input-guide',
      content:
        'Here, you can play the role of the enemy force, trying to send false information about how many soldiers are remaining.',
    },
    {
      target: '.oracle-guide',
      content:
        'Depending on the data sent, see how the headquarters (smart contract) is updated! ',
    },
    {
      target: '.stepper-guide',
      content:
        'You can check the progress of your carrier pigeon’s flight (the sent data) using these blockchain transaction checkpoints.',
    },
  ],
};

const getHandleTourEnd = tourEndCallback => data => {
  const { type } = data;
  if (type === joyrideEvents.TOUR_END) {
    if (tourEndCallback && typeof tourEndCallback === 'function')
      tourEndCallback();
  }
};

const navigationGuide = {
  steps: [
    {
      target: '.oracle-guide',
      content: 'Your carrier pigeon arrived at the Headquarters',
      disableBeacon: true, // auto start
    },
    {
      target: '.input-guide',
      content:
        'Try again using different data inputs, or continue to the next step',
    },
    {
      target: '.navigate-trusted-guide',
      content:
        'Click here to start experimenting with Trusted Decentralized Oracles (that use TEE)',
    },
  ],
};

const Guide = props => {
  const {
    showGlobalGuide,
    onGlobalTourEnd,
    showNavigationGuide,
    onNavigationTourEnd,
    theme,
  } = props;

  return (
    <>
      <Joyride
        steps={globalGuide.steps}
        callback={getHandleTourEnd(onGlobalTourEnd)}
        continuous={true}
        showSkipButton={true}
        run={showGlobalGuide}
        spotlightPadding={10}
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
            textColor: theme.palette.text.primary,
          },
          buttonClose: {
            display: 'none',
          },
        }}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'End',
          next: 'Next',
          skip: 'Skip',
        }}
      />
      <Joyride
        steps={navigationGuide.steps}
        callback={getHandleTourEnd(onNavigationTourEnd)}
        continuous={true}
        showSkipButton={true}
        run={showNavigationGuide}
        spotlightPadding={10}
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
            textColor: theme.palette.text.primary,
          },
          buttonClose: {
            display: 'none',
          },
        }}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'End',
          next: 'Next',
          skip: 'Skip',
        }}
      />
    </>
  );
};

export default withTheme(Guide);
