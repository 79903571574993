import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { createMediaQuery } from '../../../utils/css-utils';
import Comics from './Comics';
import Sandbox from './Sandbox';
import Navigation from './Navigation';
import Guide from './Guide';

const mediumDisplay = createMediaQuery(1080).down;
const largeDisplay = createMediaQuery(1080).up;

const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  title: {},
  mainContainer: {
    margin: 'auto',
    display: 'grid',
    gridRowGap: '2rem',
    gridColumnGap: '2rem',
    paddingBottom: '3rem',
    width: '100%',
    maxWidth: '1660px',
    [largeDisplay]: {
      gridTemplateColumns: '1fr 2fr',
      gridTemplateRows: '4fr 1fr',
    },
    [mediumDisplay]: {},
  },
  comicsContainer: {
    overflow: 'auto',
    [largeDisplay]: {
      gridColumnStart: 1,
      gridRowStart: 1,
      gridRowEnd: 3,
      height: '45rem',
    },
    [mediumDisplay]: {},
  },
  sandboxContainer: {
    [largeDisplay]: {
      gridColumnStart: 2,
      gridRowStart: 1,
    },
    [mediumDisplay]: {},
  },
  navigationContainer: {
    //align bottom
    marginTop: 'auto',
    [largeDisplay]: {
      gridColumnStart: 2,
      gridRowStart: 2,
    },
    [mediumDisplay]: {},
  },
  spacer: {
    flex: 1,
  },
});

const Oracle = props => {
  const { title, comics, navigation, sandbox, guide, classes } = props;

  return (
    <>
      {guide && <Guide {...guide} />}
      <div className={classes.root}>
        <h1 className={classes.title}>{title}</h1>
        <div className={classes.spacer} />
        <div className={classes.mainContainer}>
          <div className={classNames(classes.comicsContainer, 'comics-guide')}>
            <Comics {...comics} />
          </div>
          <div
            className={classNames(classes.sandboxContainer, 'sandbox-guide')}
          >
            <Sandbox {...sandbox} />
          </div>
          <div className={classes.navigationContainer}>
            <Navigation {...navigation} />
          </div>
        </div>
        <div className={classes.spacer} />
      </div>
    </>
  );
};

export default withStyles(styles)(Oracle);
