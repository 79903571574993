import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Inputs from './sandbox/Inputs';
import Stepper from './sandbox/Stepper';
import Headquarter from './sandbox/Headquarter';
import SpinnerPigeon from './sandbox/SpinnerPigeon';
import wave from '../../../assets/oracles/wave.svg';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',

    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
    background: `url(${wave})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    display: 'grid',
    gridRowGap: '1rem',
    gridColumnGap: '1rem',

    gridTemplateColumns: '1fr 3rem 1fr',
  },
  headerContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    gridRowStart: 1,
  },
  inputsContainer: {
    gridColumnStart: 1,
    gridRowStart: 2,
  },
  spinnerContainer: {
    gridColumnStart: 2,
    gridRowStart: 2,
  },
  headquarterContainer: {
    gridColumnStart: 3,
    gridRowStart: 2,
  },
  stepperContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    gridRowStart: 3,
    //align bottom
    marginTop: 'auto',
  },
  title: {
    marginTop: 0,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'flex-end',
  },
  subtitleText: {
    marginLeft: 'auto',
  },
  badge: {
    height: '0.5rem',
    width: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.background.red,
    margin: 'auto 0.5rem',
  },
  trustedBadge: {
    backgroundColor: theme.palette.background.green,
  },
});

const Sandbox = props => {
  const {
    isTrusted,
    subtitle,
    inputs,
    headquarter,
    pigeon,
    stepper,
    classes,
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.subtitle}>
          <div className={classes.subtitleText}>{subtitle}</div>
          <div
            className={classNames(
              classes.badge,
              isTrusted && classes.trustedBadge,
            )}
          />
        </div>
        <h2 className={classes.title}>It's your turn now:</h2>
      </div>
      <div className={classes.InputsContainer}>
        <Inputs {...inputs} />
      </div>
      <div className={classes.spinnerContainer}>
        <SpinnerPigeon {...pigeon} />
      </div>
      <div className={classes.headquarterContainer}>
        <Headquarter {...headquarter} />
      </div>
      <div className={classes.stepperContainer}>
        <Stepper {...stepper} />
      </div>
    </div>
  );
};

export default withStyles(styles)(Sandbox);
