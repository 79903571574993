import React, { useContext } from 'react';
import { WalletContext } from '../provider/WalletProvider';
import { createIcon } from '@download/blockies';
import { DEVELOPERS_HOME_URL } from '../../config';

const HeaderContainer = props => {
  const { connected, disconnect, address } = useContext(WalletContext);
  const userName = address;
  const userImage =
    address &&
    createIcon({
      seed: address.toLowerCase(),
    }).toDataURL();
  const title = 'iExec for Blockchain developers';
  const link = DEVELOPERS_HOME_URL;
  return (
    <>
      {React.Children.map(props.children, (child, i) => {
        return React.cloneElement(child, {
          title,
          link,
          connected,
          disconnect,
          userName,
          userImage,
        });
      })}
    </>
  );
};

export default HeaderContainer;
