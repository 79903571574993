import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '16px',
        },
        body: {
          fontFamily: ['Proxima-Nova', 'sans-sherif', 'Roboto', 'Arial'].join(
            ',',
          ),
        },
        backgroundColor: '#FFFFFF',
      },
    },
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    secondary: {
      light: '#D4DCEA',
      main: '#A2B5D2',
      dark: '#4F5C73',
      contrastText: '#647799',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
      dark: '#A2B5D2',
      red: '#FD2C2B',
      green: '#02C06F',
      footer: '#304058',
      hover: '#304058',
      skeleton: '#A3BEDC',
      shadow: '#A2B5D2',
    },
    text: {
      primary: '#304058',
      secondary: '#A3BEDC',
      disabled: '#A2B5D2',
      error: '#FD2C2B',
      link: '#2749DD',
    },
  },
});

const ThemeProvider = props => (
  <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
);

export default ThemeProvider;
