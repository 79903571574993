import React, { useContext } from 'react';
import { WalletContext } from '../provider/WalletProvider';
import LoginContainer from '../container/LoginContainer';
import Login from '../presentational/Login';
import { CHAIN } from '../../config';

const LoginGuard = props => {
  const { connected, chainId } = useContext(WalletContext);
  return (
    <>
      {!connected || chainId !== CHAIN ? (
        <LoginContainer>
          <Login />
        </LoginContainer>
      ) : (
        props.children
      )}
    </>
  );
};

export default LoginGuard;
