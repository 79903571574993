import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.footer,
    width: '100%',
    padding: '0.75rem 3.125rem',
    minWidth: '720px',
  },
  text: {
    margin: 0,
    fontWeight: 'bold',
    color: theme.palette.secondary.light,
    textAlign: 'center',
    '& a': {
      color: theme.palette.secondary.light,
    },
  },
});

const Footer = props => (
  <div className={props.classes.root}>
    <p className={props.classes.text}>
      © Copyright {new Date().getFullYear()} iExec Blockchain Tech -{' '}
      <Link to="/privacy">Privacy</Link>
    </p>
  </div>
);

export default withStyles(styles)(Footer);
