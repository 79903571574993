import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import logo from '../../../../assets/oracles/pigeon.svg';

const styles = theme => ({
  '@keyframes bounce': {
    '0%': {
      // transform: 'scale(1)',
    },
    '50%': {
      paddingTop: '0.8rem',
      // transform: 'scale(0.92)',
    },
    '100%': {
      // transform: 'scale(1)',
    },
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'inline-flex',
  },
  logo: {
    margin: 'auto',
    width: '100%',
  },
  isInFlight: {
    animation: '$bounce 0.7s infinite',
  },
});

const SpinnerPigeon = props => {
  const { classes, isInFlight } = props;
  return (
    <div className={classes.root}>
      {isInFlight && (
        <img
          className={classNames(classes.logo, classes.isInFlight)}
          src={logo}
          alt="pigeon in flight"
        />
      )}
    </div>
  );
};

export default withStyles(styles)(SpinnerPigeon);
