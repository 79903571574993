import React, { useContext } from 'react';
import { WalletContext } from '../provider/WalletProvider';
import { CHAIN } from '../../config';

const LoginContainer = props => {
  const { connected, connect, availableProviders, chainId } = useContext(
    WalletContext,
  );
  const wrongChain = connected && chainId !== CHAIN;

  return (
    <>
      {React.Children.map(props.children, (child, i) => {
        return React.cloneElement(child, {
          connect,
          availableProviders,
          wrongChain,
        });
      })}
    </>
  );
};

export default LoginContainer;
