import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import ThemeProvider from './components/provider/ThemeProvider';
import WalletProvider from './components/provider/WalletProvider';
import ClockProvider from './components/provider/ClockProvider';
import HeaderContainer from './components/container/HeaderContainer';
import Header from './components/presentational/Header';
import Content from './components/presentational/Content';
import Footer from './components/presentational/Footer';

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <CssBaseline />
        <WalletProvider>
          <ClockProvider>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <HeaderContainer>
                <Header />
              </HeaderContainer>
              <Content />
              <Footer />
            </div>
          </ClockProvider>
        </WalletProvider>
      </ThemeProvider>
      <Route
        exact
        path="/privacy"
        render={() => {
          window.location.href = 'privacy.html';
        }}
      />
    </Router>
  );
};

export default App;
