import React, { useState, useEffect } from 'react';
import {
  init,
  chooseProvider,
  disconnectProvider,
  getWeb3,
} from '../../services/web3';
import { CHAIN } from '../../config';

export const WalletContext = React.createContext(null);

const WalletProvider = props => {
  const [providerEnabled, setProviderEnabled] = useState(false);
  const [providerName, setProviderName] = useState(undefined);
  const [chainId, setChainId] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [availableProviders, setAvailableProviders] = useState([]);
  useEffect(() => {
    init({
      onProviderChanged: setProviderName,
      onEnabled: (chainId, account) => {
        setProviderEnabled(true);
        setChainId(chainId);
        setAddress(account);
      },
      onUserDenied: () => setProviderEnabled(false),
      onAccountChange: setAddress,
      onChainChange: setChainId,
      onDisconnect: () => {
        setProviderEnabled(false);
        setProviderName(undefined);
        setAddress(undefined);
        setChainId(undefined);
      },
      onAvailableProviders: setAvailableProviders,
    });
  }, []);

  const connected = providerEnabled && !!address;

  const getWeb3Provider = connected ? () => getWeb3() : undefined;

  const connect = async (provider, chainId = CHAIN) => {
    chooseProvider(provider, { chainId });
  };

  const disconnect = () => {
    disconnectProvider();
  };

  return (
    <WalletContext.Provider
      value={{
        connected,
        availableProviders,
        providerName,
        address,
        chainId,
        connect: !connected ? connect : undefined,
        disconnect: connected ? disconnect : undefined,
        getWeb3Provider,
      }}
    >
      {props.children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
