import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import NavStep from './NavStep';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const Navigation = props => (
  <div className={props.classes.root}>
    {props.steps &&
      props.steps.map((stepProps, i) => <NavStep key={i} {...stepProps} />)}
  </div>
);

export default withStyles(styles)(Navigation);
