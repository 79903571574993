import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    flex: 1,
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
  },
  active: {
    '& a': {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
  badgeImage: {
    height: '3.6rem',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  badge: {
    margin: 'auto',
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    padding: '1.2rem',
    height: '3.6rem',
    width: '3.6rem',
    borderRadius: '1.8rem',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  badgeActive: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
  },
});

const ExtLink = props =>
  props.href ? <a href={props.href}>{props.children}</a> : <Link {...props} />;

const NavStep = props => (
  <div
    className={classNames(
      props.classes.root,
      props.active && props.classes.active,
    )}
  >
    <ExtLink {...props.link} className={props.guideClassName}>
      {props.image && (
        <div className={props.classes.badgeImage}>
          <img src={props.image} alt="" />
        </div>
      )}
      {!props.image && (
        <div
          className={classNames(
            props.classes.badge,
            props.active && props.classes.badgeActive,
          )}
        >
          {props.stepNum}
        </div>
      )}
      <div className={props.classes.text}>
        <p>{props.stepText}</p>
      </div>
    </ExtLink>
  </div>
);

export default withStyles(styles)(NavStep);
