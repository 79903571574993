// bootstrap styles map from
// https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.css
export const BOOTSTRAP_STYLES_MAP = {
  dFlex: {
    display: 'flex !important',
  },
  alignItemsCenter: {
    alignItems: 'center !important',
  },
  justifyContentBetween: {
    justifyContent: 'space-between !important',
  },
  justifyContentStart: {
    justifyContent: 'flex-start !important',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end !important',
  },
};

export const createMediaQuery = breakpoint => ({
  up: `@media (min-width: ${breakpoint}px)`,
  down: `@media (max-width: ${breakpoint - 1}px)`,
});
