import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ORACLE_STATUS } from '../../../../utils/iexec-utils';
import logo from '../../../../assets/oracles/headquarter.svg';

const styles = theme => ({
  root: {
    padding: '0 2rem 0 0',
    display: 'flex',
    flexDirection: 'row',
  },
  logo: { margin: 'auto', maxWidth: '30%' },
  tableContainer: {
    width: '50%',
  },
  table: {
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 2px 4px 0 ${theme.palette.background.shadow}`,
    borderRadius: '5px',
    minHeight: '17.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  fromUser: {
    fontWeight: 'bold',
  },
  tableFooter: {
    height: '2rem',
    marginTop: 'auto',
    padding: '0.5rem 0',
    borderRadius: '0 0 5px 5px',
  },
  wait: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  success: {
    backgroundColor: theme.palette.background.green,
  },
  fail: {
    backgroundColor: theme.palette.background.red,
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
});

const Headquarter = props => {
  const { values, address, status, link, classes } = props;
  let statusLabel;
  let statusClass;

  switch (status) {
    case ORACLE_STATUS.SUCCESS:
      statusLabel = 'Accepted';
      statusClass = classes.success;
      break;
    case ORACLE_STATUS.FAIL:
      statusLabel = 'Declined';
      statusClass = classes.fail;
      break;
    case ORACLE_STATUS.WAIT:
      statusLabel = 'Awaiting';
      statusClass = classes.wait;
      break;
    default:
  }

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={logo} alt="headquarter" />
      <div className={classes.tableContainer}>
        <h3>Number of soldiers</h3>
        <div className={classNames(classes.table, 'oracle-guide')}>
          <h3>Headquarter</h3>
          {values &&
            values.map(({ value, requester }, i) => (
              <div
                key={i}
                className={classNames(
                  address.toLocaleLowerCase() === requester.toLowerCase() &&
                    classes.fromUser,
                )}
              >
                {value}
              </div>
            ))}
          <div className={classNames(classes.tableFooter, statusClass)}>
            <strong>{statusLabel}</strong>
          </div>
        </div>
        {link && (
          <p>
            <a
              className={classes.link}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              See Decentralized Oracle Code
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(Headquarter);
