import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputBase, ButtonBase } from '@material-ui/core';
import classNames from 'classnames';

import allyLogo from '../../../../assets/oracles/icon-soldier.svg';
import enemyLogo from '../../../../assets/oracles/icon-enemy.svg';

const styles = theme => ({
  root: {
    padding: '0 0 0 2rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    flex: 1,
    textAlign: 'left',
  },
  formControl: {
    width: '100%',
    marginRight: 'auto',
    alignItems: 'flex-start',
  },
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  inputLine: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      margin: 'auto',
    },
    '& > img': {
      margin: 'auto',
      marginLeft: '1rem',
      height: '3rem',
      width: '2.5rem',
    },
    marginBottom: '0.5rem',
  },
  roundInputRoot: {
    height: '2rem',
    borderRadius: '1rem',
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 2px 4px 0 ${theme.palette.background.shadow}`,
    padding: '0 1rem',
  },
  inputError: {
    color: 'red',
  },
  roundButtonRoot: {
    height: '2rem',
    borderRadius: '1rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '0 1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light,
    },
  },
  spacer: {
    flex: 1,
  },
});

const Inputs = props => {
  const { isReadyToSend, handleAllySend, handleEnemySend, classes } = props;

  const [enemyData, setEnemyData] = useState(1000);

  const handleEnemyDataChange = event => {
    const { value } = event.target;
    const intValue = parseFloat(value);
    if (Number.isInteger(intValue) && intValue >= 0 && intValue <= 7000000000) {
      setEnemyData(intValue);
    }
  };

  return (
    <div className={classNames(classes.root, 'input-guide')}>
      <div className={classes.spacer} />

      <div className={classNames(classes.inputGroup, 'ally-input-guide')}>
        <FormControl className={classes.formControl}>
          <label htmlFor="ally-input" className={classes.inputLabel}>
            Battlefield data source
          </label>
          <div className={classes.inputLine}>
            <InputBase
              id="ally-input"
              classes={{ root: classes.roundInputRoot }}
              defaultValue="~ 1000"
              disabled={true}
            />
            <img src={allyLogo} alt="ally message" />
          </div>
        </FormControl>
        <ButtonBase
          classes={{ root: classes.roundButtonRoot }}
          disabled={!isReadyToSend}
          onClick={() => {
            handleAllySend();
          }}
        >
          Generate
        </ButtonBase>
      </div>
      <div className={classes.spacer} />
      <div className={classNames(classes.inputGroup, 'enemy-input-guide')}>
        <FormControl className={classes.formControl}>
          <label htmlFor="enemy-input" className={classes.inputLabel}>
            Enemy data source
          </label>
          <div className={classes.inputLine}>
            <InputBase
              id="enemy-input"
              classes={{
                root: classes.roundInputRoot,
                error: classes.inputError,
              }}
              value={enemyData}
              onChange={handleEnemyDataChange}
              type="number"
              disabled={!isReadyToSend}
            />
            <img src={enemyLogo} alt="enemy message" />
          </div>
        </FormControl>
        <ButtonBase
          classes={{ root: classes.roundButtonRoot }}
          disabled={!isReadyToSend}
          onClick={() => {
            handleEnemySend(enemyData);
          }}
        >
          Generate
        </ButtonBase>
      </div>
      <div className={classes.spacer} />
    </div>
  );
};

export default withStyles(styles)(Inputs);
