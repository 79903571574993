import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createMediaQuery } from '../../../utils/css-utils';

const mediumDisplay = createMediaQuery(1080).down;
const largeDisplay = createMediaQuery(1080).up;

const styles = theme => ({
  root: {
    [largeDisplay]: {
      marginLeft: 'auto',
      maxWidth: '540px',
      '& > img': {
        display: 'block',
        width: '100%',
        maxWidth: '540px',
        maxHeight: '240px',
        borderRadius: '5px',
        backgroundColor: theme.palette.background.skeleton,
        marginBottom: '0.5rem',
      },
      '& > img:last-child': {
        marginBottom: '0',
      },
    },
    [mediumDisplay]: {
      display: 'flex',
      flexDirection: 'row',
      '& > img': {
        width: '540px',
        height: '240px',
        borderRadius: '5px',
        backgroundColor: theme.palette.background.skeleton,
        marginRight: '0.5rem',
      },
      '& > img:last-child': {
        marginRight: '0',
      },
    },
  },
});

const Comics = props => {
  const { images, classes } = props;
  return (
    <div className={classes.root}>
      {images && images.map((img, i) => <img src={img} key={i} alt="" />)}
    </div>
  );
};

export default withStyles(styles)(Comics);
